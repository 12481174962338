import React, { useState, useEffect } from 'react';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import theme, { COLORS } from '../../../utils/theme';
import apiRefMessages from '../../../utils/messages/en/apiRefMessages';
import CodeBlock from '../../common/CodeBlock';
import Typography from '../../common/Typography';
import contentCopyIcon from '../../../../static/images/contentCopyIcon.svg';
import CodeLanguages from '../../../utils/messages/CodeLanguages';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { StyledPre } from '../../docs/MDXComponents';

const useStyles = {
  titleWrapper: { display: 'flex' },
  mimeTypeWrapper: { padding: '11.5px 0px 11.5px 10px' },
  buttonWrapperDefault: { backgroundColor: 'inherit' },
  titleBox: { padding: '9px 0px 9px 12px' },
  iconButtonStyle: { padding: theme.spacing(0) },
  headerRightStyle: { display: 'flex' },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[200],
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    height: '38px',
  },
  menuItems: {
    color: COLORS.SECONDARY_DUSK,
    height: '19px',
    minHeight: 'unset',
    width: '95px',
    padding: '0px',
    marginBottom: '2px',
    ':hover': {
      backgroundColor: COLORS.BACKGROUND_SECONDARY,
    },
  },
  buttonStyle: {
    ':hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiButton-endIcon': {
      marginLeft: '4px',
      marginRight: '0px',
    },
    height: '22px',
    padding: '0px',
    justifyContent: 'flex-end',
  },
  expandedButtonStyle: {
    width: '95px',
    justifyContent: 'space-between',
    margin: '4px 4px',
    '& .MuiButton-endIcon': {
      marginLeft: '0px',
      marginRight: '0px',
    },
    height: '22px',
    padding: '0px',
    backgroundColor: COLORS.PRIMARY_AIR,
  },
  expandIcon: {
    height: '16px',
    widht: '16px',
    fontSize: '16px',
  },
  dropdownWrapper: {
    display: 'inherit',
    alignItems: 'center',
    margin: '0px 10px',
  },
  buttonWrapper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px 4px 0px 0px',
  },
  menuListStyle: {
    '& .MuiList-root.MuiMenu-list': { padding: '2px 4px' },
    '& .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper': {
      borderRadius: '0px 0px 4px 4px',
      marginLeft: '4px',
    },
  },
  listItem: {
    padding: '2px 2px 2px 4px',
    color: COLORS.SECONDARY_DUSK,
  },
  selectedItemStyle: {
    paddingRight: '0px',
    paddingLeft: '4px',
    color: COLORS.SECONDARY_DUSK,
  },
  copyIcon: {
    padding: '0px 10px',
    margin: 'auto',
    borderLeft: '1px groove',
    borderLeftColor: theme.palette.grey[600],
  },
};

export interface Item {
  id: string;
  title: string;
  content: string;
}

export interface RequestResponseProps {
  items?: Item[];
  type?: 'response' | 'request';
  className?: string;
  children?: string;
  isJSONFoldable?: boolean;
  snippetId?: string;
  isAPI?: boolean;
}

const addCopyTextEventToDataLayer = (id) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'copyTextEventSubmission',
      snippetId: id,
    });
  }
  return '';
};

const generateUniqueId = (type, getCopyText, snippetId, selectedOption,flags) => {
  let uniqueId = snippetId;
  if (navigator.clipboard) {
    navigator.clipboard.writeText(getCopyText());
  }
  if (type === 'response' || type === 'request') {
    uniqueId += '__' + selectedOption;
  }
  if(flags && flags.enableDocAnalyticsEvents === true){
  addCopyTextEventToDataLayer(uniqueId);
  }
};

const RequestResponse: React.FC<RequestResponseProps> = (props) => {
  const { items, className, children, isJSONFoldable, type, snippetId, isAPI } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const flags = useFlags();
  const open = Boolean(anchorEl);
  let code = '';
  let lang = '';
  let option = '';
  const parseTitle = (type?) => {
    let lang = '';
    if (!type) {
      if (items) {
        lang = items[selectedIndex].id;
      } else if (className) {
        lang = className;
      }
    } else {
      lang = type;
    }
    if (lang.includes('_')) {
      lang = lang.split('_')[1];
    } else if (lang.includes('-')) {
      lang = lang.split('-')[1];
    }
    return CodeLanguages[lang] ? CodeLanguages[lang] : lang;
  };
  if (items) {
    option = parseTitle(items[0].id);
  }
  const [selectedOption, setSelectedOption] = React.useState<number | string>(
    option,
  );
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    if (items) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    statusCode: number,
  ) => {
    setSelectedOption(statusCode);
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const parseLang = (type) => {
    if (type.includes('_')) {
      return type.split('_')[0];
    } else {
      return type;
    }
  };
  const buildButton = () => {
    return (
      <Box sx={open ? useStyles.buttonWrapper : {}}>
        <Button
          variant="text"
          disableElevation
          disableRipple
          onClick={handleClickListItem}
          endIcon={items ? <ExpandMoreIcon sx={useStyles.expandIcon} /> : <></>}
          sx={open ? useStyles.expandedButtonStyle : useStyles.buttonStyle}
          aria-label="language"
        >
          <Typography
            type="DESKTOP_CODE_PRIMARY"
            sx={useStyles.selectedItemStyle}
          >
            {parseTitle()}
          </Typography>
        </Button>
      </Box>
    );
  };
  if (children) {
    code = children.trim();
    if(children.includes('{/script}'))
    {
      const content = children.trim();
      code = content.replace(/\{script(.*?)\}/g, '<script$1>').replace(/\{\/script\}/g, '</script>');
    }
    lang = '';
    if (className) {
      lang = className.replace(/language-/, '');
    } else {
      lang = 'bash';
    }
    lang = CodeLanguages[lang] ? CodeLanguages[lang] : lang;
  }
  if (typeof code !== 'string' && lang === 'json') {
    code = JSON.stringify(code);
  }
  if (!items && !children) {
    return <></>;
  }
  const getCopyText = () => {
    if (items) {
      return items[selectedIndex].content;
    } else if (code) {
      return code;
    } else {
      return '';
    }
  };
  const buildCopyButton = () => {
    return (
      <Box sx={useStyles.copyIcon}>
        <IconButton
          onClick={() =>
            generateUniqueId(type, getCopyText, snippetId, selectedOption,flags)
          }
          sx={useStyles.iconButtonStyle}
        >
          <img src={contentCopyIcon} width="16px" height="16px" alt="copy" />
        </IconButton>
      </Box>
    );
  };
  return (
    <StyledPre>
      <Box sx={useStyles.headerBox}>
        <Box sx={useStyles.titleWrapper}>
          {props.type && 
            <Box sx={useStyles.titleBox}>
              <Typography type="DESKTOP_CODE_ALLCAPS" component="span">
                {props.type === 'request'
                  ? apiRefMessages.request
                  : apiRefMessages.response}
              </Typography>
            </Box>
          }
        </Box>
        <Box sx={useStyles.headerRightStyle}>
          <Box sx={useStyles.dropdownWrapper}>
            {buildButton()}
            {items && 
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  role: 'listbox',
                }}
                elevation={0}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transitionDuration={{
                  appear: 0,
                  enter: 0,
                  exit: 0,
                }}
                sx={useStyles.menuListStyle}
              >
                {items.map((item, index) => 
                  <MenuItem
                    key={item.id}
                    selected={index === selectedIndex}
                    onClick={(event) =>
                      handleMenuItemClick(event, index, parseTitle(item.id))
                    }
                    sx={useStyles.menuItems}
                  >
                    <Typography
                      type="DESKTOP_CODE_SECONDARY"
                      sx={useStyles.listItem}
                    >
                      {parseTitle(item.id)}
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            }
          </Box>
          {buildCopyButton()}
        </Box>
      </Box>
      <CodeBlock
        code={items ? items[selectedIndex].content : code}
        lang={parseLang(items ? items[selectedIndex].id : lang)}
        dependencies={[selectedIndex]}
        isJSONFoldable={isJSONFoldable}
        isAPI={isAPI}
      />
    </StyledPre>
  );
};
export default RequestResponse;
